














import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import Header from '@/components/Header.vue'
import Content from '@/components/Content.vue'
import Tabs from '@/components/Tabs.vue'
import { NavigationItem } from '@/models/NavigationItem'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {
    Button,
    Header,
    Content,
    Tabs
  }
})
export default class Admin extends mixins(UserMixin) {
  get tabs(): NavigationItem[] {
    return this.user.role === 'admin'
      ? [
        {
          text: 'Aktivitäten',
          name: 'admin/farm/activities',
          isVisible: () => true
        },
        {
          text: 'Kameras',
          name: 'admin/farm/cameras',
          isVisible: () => true
        },
        {
          text: 'Kommunikation',
          name: 'admin/farm/communication',
          isVisible: () => true
        },
        {
          text: 'Zusatzprodukte',
          name: 'admin/farm/items',
          isVisible: () => true
        },
        {
          text: 'Bestellungen',
          name: 'admin/orders',
          isVisible: () => true
        },
        { text: 'Benutzer', name: 'admin/users', isVisible: () => true }
      ]
      : [
        {
          text: 'Aktivitäten',
          name: 'admin/farm/activities',
          isVisible: () => true
        },
        {
          text: 'Kameras',
          name: 'admin/farm/cameras',
          isVisible: () => true
        },
        {
          text: 'Kommunikation',
          name: 'admin/farm/communication',
          isVisible: () => true
        },
        {
          text: 'Zusatzprodukte',
          name: 'admin/farm/items',
          isVisible: () => true
        }
      ]
  }
}
